@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');

html,
body {
  font-family: "Space Grotesk", sans-serif;
  height: 100%;
  margin: 0;
  color: white;
}

body {
  align-items: center;
  display: flex;
  justify-content: center;

  background: linear-gradient(to bottom right, #000000, #00ff97);
  background: black;
  background-size: 200% 200%;
  font-size: 1.5rem;

  -webkit-animation: bganim 25s ease infinite;
  -moz-animation: bganim 25s ease infinite;
  animation: bganim 25s ease infinite;
}

@-webkit-keyframes bganim {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes bganim {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes bganim {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}

main {
  color: #fff6d5;
  text-align: center;
}

.logo {
  height: 20em;
}

.heart:after {
  content: "❤️";

  font-size: 1.75em;
}

.heading {
  color: white;
  font-size: 2em;
  font-weight: 700;
}

.description {
  margin-top: -1em;
  color: white;
  font-size: 0.9em;
  text-align: center;
}

.container {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

button {
	box-shadow: 0px 10px 14px -7px #3e7327;
	background:linear-gradient(to bottom, #77b55a 5%, #72b352 100%);
	background-color:#77b55a;
	border-radius:4px;
	border:1px solid #4b8f29;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-size: 0.8em;
	font-weight:bold;
	padding:6px 12px;
	text-decoration:none;
	text-shadow:0px 1px 0px #5b8a3c;
    font-family: "Space Grotesk", sans-serif;
}

button:hover {
	background:linear-gradient(to bottom, #72b352 5%, #77b55a 100%);
	background-color: #72b352;
}

button:active {
	position:relative;
	top:1px;
}
